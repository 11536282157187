import { faBridgeWater, faEarListen, faFaceLaughBeam, faMusic, faPeopleGroup, faPersonWalkingDashedLineArrowRight, faSailboat, faShip, faTents, faWaterLadder } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import React from 'react';
import PageSection from '../page-section';
import EventListItem from './event-list-item';
const EventsListSection = () => {
    const goToTickets= () => navigate('/swieto-wisly/bilety');
    const goToFacebookEvent = () => {
        if (typeof window !== `undefined`) window.open("https://www.facebook.com/events/593998082365851/", '_blank');
     }; 
    return (
        <PageSection className='bg-white text-black' id="events-section" data-bs-spy="scroll" data-bs-target="#read-more-events">
            <h2><strong>Atrakcje</strong> Święta Wisły 2022</h2>
            <div className="py-3" ></div>
            <EventListItem
                icon={faTents}
                eventTitle="Wioska urzeczańska"
                desc="Centralnym miejscem obchodów będzie wioska urzeczańska w wyjątkowej lokalizacji - na wyspie, na której zaplanowanych jest szereg atrakcji przybliżającej kulturę i tradycję Urzecza. Wioska będzie otwarta dla wszystkich, bez konieczności rezerwacji w godz. 12.00-16.00."
                buttonText="Więcej"
                sectionLink={true}
                sectionId="#more-section-village"
                buttonId={"read-more-village"}
            />
            <EventListItem
                icon={faShip}
                eventTitle="Rejsy na wyspę"
                desc="Bezpłatne rejsy wiślane będą wypływały z 3 lokalizacji w godz. 12.00-16.00. Łodzie które popłyną w rejsach będą drewniane i tradycyjnie przystrojone. Rejsy zakończymy na wyspie wiślanej Poniatówka, gdzie zorganizowana będzie wioska urzeczańska. Bilety dostępne w rezerwacji online oraz na miejscu w dniu wydarzenia."
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faSailboat}
                eventTitle="Parada statków"
                desc="Wielka parada statków, które popłyną w ok. 1-godzinny rejs po Wiśle. Start o godz. 18.00 z plaży Rusałka (Zoo). Zakończenie rejsu na plaży Rusałka (Zoo). Bilety dostępne w rezerwacji online oraz na miejscu w dniu wydarzenia."
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faBridgeWater}
                eventTitle="Spływ kajakowy"
                desc='Wioślana Masa Krytyczna organizowana przez "Federację Przystań Warszawa". Start z plaży Saskiej o godz. 10.30, zakończenie planowane na Młocinach. Zapisy oraz informacje na stronie Facebook.'
                buttonText="Rezerwuj"
                buttonOnClick={goToFacebookEvent}
            />
            <EventListItem
                icon={faPeopleGroup}
                eventTitle="Zajęcia SUP"
                desc="3 warsztaty z pływania deską SUP w małych 8-osobowych grupach. Zajęcia trwają 1 godzinę i są skierowane do osób początkujących. Starty od godz. 12.00 z plaży przy płycie Desantu. Zapisy wyłącznie poprzez rezerwację online."
                buttonText="Rezerwuj"
                buttonOnClick={goToTickets}
            />
            <EventListItem
                icon={faMusic}
                eventTitle="Koncert pieśni"
                desc="Koncert pieśni wiślanych w wykonaniu chóru Centrum Myśli Jana Pawła II pod batutą Jana Krutuli. Godzina. 19.30 w Pawilonie Kamień. Wstęp wolny."
            />
        </PageSection>
    );
}

export default EventsListSection;