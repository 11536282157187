import { faFileText, faLink, faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
const AboutText = () => {
    const { navigate } = useI18next();

    const goToTickets = () => navigate('/swieto-wisly/bilety');
    const getPdfMap = () => { 
        if (typeof window !== `undefined`) window.open("https://rejsy.waw.pl/pobierz/%C5%9Awi%C4%99to%20Wis%C5%82y%202022%20-%20Mapa%20obchod%C3%B3w.pdf", '_blank');
    };
    const openMapInNewTab = () => { 
        if (typeof window !== `undefined`) window.open("https://www.google.com/maps/d/u/4/edit?mid=1_s1bDYtNdOG7dqvnI30ih8W6brKGJ8Q&usp=sharing", '_blank');
    }; 
    const openPdfLineup = () => { 
        if (typeof window !== `undefined`) window.open("https://rejsy.waw.pl/pobierz/%C5%9Awi%C4%99to%20Wis%C5%82y%202022%20-%20Program%20obchod%C3%B3w.pdf", '_blank');
    }; 
    return (
        <>
            <div className="pt-5"></div>
            <p>W tym roku <strong>3 września</strong> będziemy obchodzić po raz kolejny Święto Wisły.
                Obchody Święta Wisły obejmą i tym razem <strong>wiele atrakcji</strong>.</p>
            <a id="read-more-events" href="#events-section" role="button" className="btn btn-outline-light btn mb-3 mb-sm-none">Zobacz atrakcje</a>

            <div className="pt-5"></div>
            <h4>Bilety</h4>
            <p>Święto Wisły jest imprezą otwartą. Jednak wiele z atrakcji takich jak np. rejsy będzie biletowanych. Aby zagwarantować sobie miejsce należy dokonać <strong>rezerwacji bezpłatnego biletu</strong>. Część biletów na rejsy będzie dostępna do nabycia wyłącznie na miejscu w dniu wydarzenia.</p>
            <button onClick={goToTickets} type="button" className="btn btn-secondary btn mb-3 mb-sm-none">Rezerwuj bilety</button>

            <div className="pt-5"></div>
            <h4>Mapa i program obchodów</h4>
            <p>Aby ułatwić zapoznanie się z przebiegiem imprezy oraz wybór odpowiednich atrakcji, przygotowaliśmy mapę i program obchodów Święta Wisły, w wersji drukowanej oraz online.</p>
            <div className="d-flex"> <button onClick={getPdfMap} type="button" className="btn btn-outline-light btn mb-3 mb-sm-none"><FontAwesomeIcon icon={faMapMarked} /> Mapa PDF</button>
                <button onClick={openMapInNewTab} type="button" className=" ms-2 btn btn-outline-light btn mb-3 mb-sm-none"><FontAwesomeIcon icon={faLink} /> Mapa Online</button>
                <button onClick={openPdfLineup} type="button" className=" ms-2 btn btn-outline-light btn mb-3 mb-sm-none"><FontAwesomeIcon icon={faFileText} /> Program PDF</button>
            </div>

        </>

    );
}

export default AboutText;