import React from 'react';
const CountBullet = ({ count, title, desc }: { count, title, desc: string }) => {
    return (
        <div className="d-flex p-2 pb-4 pb-sm-5 justify-content-start">

            <div className="d-flex align-items-end float-end" style={{ height: '70px' }}>
                <h1 className="mb-0 mt-0" style={{ fontSize: '3.5em', fontWeight: 900, }}><strong>{count}</strong></h1>
            </div>

            <div className="flex-column ps-2 flex-grow-1">
                <div className="d-flex align-items-end" style={{ height: '70px' }}>
                    <h4 className="mb-2"><strong>{title}...</strong></h4>
                </div>
                <div className="pt-0">
                    ...{desc}
                </div>
            </div>
        </div >
    );
}

export default CountBullet;