import { faTents } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import PageSection from '../page-section';
import lodzie from "../../images/lodzie_footer.svg";
const VillageInfoSection = () => {
    const goToTickets = () => navigate('/swieto-wisly/bilety');
    return (
        <div className="container-fluid p-0 position-relative overflow-hidden village-info-section-height" >
            <div
                className="position-absolute container-fluid h-100 p-0 village-info-section-height"
            >
                <StaticImage
                    src='../../images/wioska-urzeczanska.jpg'
                    layout="fullWidth"
                    alt=""
                    formats={["auto", "webp", "avif"]}
                    placeholder="blurred"
                    transformOptions={{ fit: `cover` }}
                    style={{ height: '750px' }}
                />
            </div>
            <div className="position-absolute container-fluid h-100 p-0 bg-primary bg-opacity-100" ></div>
            <div className="position-absolute container-fluid h-100 p-0"><div className="row h-100 align-items-end">
                <div className="col">
                    <img alt="" src={lodzie} width={"100%"} />
                </div>
            </div></div>
            <div id="more-section-village" data-bs-spy="scroll" data-bs-target="#read-more-village" className="position-absolute container-fluid h-100 p-0">
                <PageSection >
                    <h2><FontAwesomeIcon icon={faTents} /> <span className="ps-2"><strong>Wioska urzeczańska</strong></span></h2>
                    <div className="py-3"></div>
                    <p>
                        Głównym punktem programu będzie Wioska Urzeczańska. Do tymczasowej wioski dopłyniecie przystrojonymi, tradycyjnymi, drewnianymi łodziami (osoby z rezerwacjami) lub <strong>z plaży Poniatówka bez konieczności wcześniejszej rezerwacji</strong>.
                    </p>
                    <p>
                        Na wyspie wiślanej przy moście Poniatowskiego, czekać na Was będą <strong>liczne zajęcia</strong> m.in. animacje, biesiada, warsztaty rzemieślnicze przybliżające kulturę Urzecza. Oprawą będą namioty i flisacy w strojach z epoki.
                    </p>
                    <p>
                        Z biesiady nie odpłyniecie głodni. Naszym Gościom zaserwujemy <strong>tradycyjny poczęstunek</strong> z kociołka.
                    </p>
                    <div className="py-3"></div>
                    <div className="d-flex">
                        <button onClick={goToTickets} type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwuj rejs</button>
                    </div>
                </PageSection>
            </div>
        </div>
    );
}

export default VillageInfoSection;