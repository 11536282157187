import { faMapMarked, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';
const EventListItem = ({ icon, eventTitle, desc, buttonText, sectionLink, buttonOnClick, sectionId, buttonId }: { icon: IconDefinition, eventTitle, desc, buttonText?, buttonId?, sectionId?: string, sectionLink?: boolean, buttonOnClick?: MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <div className="container px-2 pb-sm-1 py-3">
            <div className='row'>
                <div className='col-sm-9'>
                    <div className='row'>
                        <div className="d-flex p-2 pt-0 justify-content-start">
                            <div className="d-flex align-items-end float-end" style={{ height: '50px' }}>
                                <h2><FontAwesomeIcon icon={icon} /></h2>
                            </div>

                            <div className="flex-column ps-3 flex-grow-1">
                                <div className="d-flex align-items-end" style={{ height: '50px' }}>
                                    <h4 className="mb-2"><strong>{eventTitle}</strong></h4>
                                </div>
                                <div className="pt-0">
                                    {desc}
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
                <div className='col-sm-3'>
                    <div className='row pt-2'>
                        {(buttonText != null && sectionLink && sectionId != null && buttonId != null) && <a id={buttonId} href={sectionId} role="button" className="btn btn-outline-dark btn mb-3 mb-sm-none">{buttonText}</a>}
                        {(buttonText != null && buttonOnClick != null) && <button onClick={buttonOnClick} type="button" className="btn btn-outline-dark btn mb-3 mb-sm-none">Rezerwuj</button>}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default EventListItem;