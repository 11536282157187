import { graphql } from "gatsby";
import * as React from "react"

import Layout from "../components/layout"
import PageSection from "../components/page-section";
import Seo from "../components/seo"
import AboutSection from "../components/vistula-day/about-section";
import EventsListSection from "../components/vistula-day/events-list-section";
import HeroImage from "../components/vistula-day/hero-image";
import MobileLogotypesSection from "../components/vistula-day/mobile-logotypes-section copy";
import VillageInfoSection from "../components/vistula-day/village-info-section";

const VistulaDayInfoPage = () => {
    return (
        <Layout>
            <Seo title={"Święto Wisły 2022"} />
            <HeroImage />
            <MobileLogotypesSection />
            <AboutSection/>
            <EventsListSection/>
            <VillageInfoSection/>
        </Layout>
    );
}

export default VistulaDayInfoPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;