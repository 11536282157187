import React from 'react';
import CountBullet from './count-bullet';
const CountBullets = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <CountBullet count="10" title="łodzi" desc="tradycyjnych na których będą odbywać się rejsy" />
                </div>
                <div className="col">
                    <CountBullet count="50" title="rejsów" desc="do których będzie można dołączyć" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <CountBullet count="470" title="bezpłatnych biletów" desc="dostępnych dla rezerwacji online oraz do nabycia na miejscu" />
                </div>
                <div className="col">
                    <CountBullet count="6" title="atrakcji" desc="w 5 lokalizacjach: wioska urzeczańska, zajęcia SUP, rejsy statkami, spływ kajakowy, a nawet koncert pieśni wiślanych!" />
                </div>
            </div>
        </div>
    );
}

export default CountBullets;