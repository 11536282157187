import React from 'react';
import PageSection from '../page-section';
import AboutText from './about-text';
import CountBullets from './count-bullets';
const AboutSection= () => {
    return (
        <PageSection id="about-vistula-day-section" data-bs-spy="scroll" data-bs-target="#read-more-vistula-day">
            <CountBullets/>
            <hr/>
            <AboutText/>
        </PageSection>
    );
}

export default AboutSection;